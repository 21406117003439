<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation # {{ quotation.jobNumber }}
          <span class="float-right">Update</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <p>
          <router-link
            custom v-slot="{ navigate }"
            :to="`/quotations/view/${quotation.id}`">
            <v-btn
              small
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              class="relative"
              style="top:-3px">
                View quotation
            </v-btn>
          </router-link>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-row v-if="quotation.customer === 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-row v-if="foundCustomers.length === 0">
              <v-col class="col-9">
                <v-text-field
                  label="Search for a customer"
                  v-model="searchTerm"></v-text-field>
              </v-col>
              <v-col class="col-3 pt-8">
                <v-btn small @click="lookupCustomers">Find customer</v-btn>
              </v-col>
            </v-row>
            <v-combobox
              v-else
              v-model="customer"
              :items="foundCustomers"
              item-text="name"
              item-value="id"></v-combobox>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4 pt-8">
            <v-btn
              v-if="customer.id"
              small
              @click="populateCustomer">
              Use this customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <h4>Customer address</h4>
            <span v-if="quotation.customerAddressOne">{{ quotation.customerAddressOne }}</span>
            <span v-if="quotation.customerAddressTwo"><br />{{ quotation.customerAddressTwo }}</span>
            <span v-if="quotation.customerAddressThree"><br />{{ quotation.customerAddressThree }}</span>
            <span v-if="quotation.customerAddressFour"><br />{{ quotation.customerAddressFour }}</span>
            <span v-if="quotation.customerAddressFive"><br />{{ quotation.customerAddressFive }}</span>
            <span v-if="quotation.customerPostcode"><br />{{ quotation.customerPostcode }}</span>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <h4 class="pt-4 pb-0">Customer telephone</h4>
            <span v-if="quotation.customerTel">{{ quotation.customerTel }}</span>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <v-btn
              class="mt-2 mb-2"
              small
              @click="(quotation.customer = 0, foundCustomers = [])">
              Change Customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <v-text-field v-model="quotation.customerReference" label="Customer reference" />
            <v-select
              :items="departments"
              v-model="quotation.department"
              label="Department"
            ></v-select>
            <v-select
              :items="contacts"
              v-model="quotation.contactId"
              item-text="name"
              item-value="id"
              label="Contact"
            ></v-select>
            <template>
              <v-date-picker
                v-if="!date"
                v-model="date"
                show-adjacent-months
                no-title
                width="100%"
              ></v-date-picker>
            </template>
            <v-text-field
              v-if="date"
              :value="prettyDate"
              append-icon="mdi-close"
              @click:append="resetDate"
              label="Quotation date">
            </v-text-field>
            <v-select
              v-model="quotation.validFor"
              :items="validities"
              label="Quotation Valid For">
            </v-select>
            <v-select
              v-model="quotation.notice"
              :items="['7 Days', '14 Days']"
              label="Notice required for starting work"
            ></v-select>
            <v-text-field
              v-model="quotation.days"
              label="Visits required"
            ></v-text-field>
            <v-text-field
              type="number"
              v-model="quotation.extraUnsociableHours"
              label="Extra % for unsociable hours"
            ></v-text-field>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="quotation.site" label="Project Address"></v-text-field>
            <v-text-field v-model="quotation.postCode" label="Project Postcode"></v-text-field>
            <div v-if="quotation.postCode">
              <app-directions
                v-if="quotation.postCode.length > 3"
                :address="quotation.site"
                :postcode="quotation.postCode"
                :origin = "quotation.start_address"
                @routeFound="populateDistances" />
            </div>
            <v-text-field v-model="quotation.distance" label="Distance"></v-text-field>
            <v-text-field v-model="quotation.travel_hours" label="Drive Time (Minutes)"></v-text-field>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4">
            <label>Job Details</label>
            <vue-editor
              id="jobDetails"
              :editor-toolbar="customToolbar"
              v-model="quotation.description" />
            <label>Notes</label>
            <vue-editor
              id="notes"
              :editor-toolbar="customToolbar"
              v-model="quotation.notes" />
            <v-text-field v-model="quotation.drawing" label="Drawing Ref"></v-text-field>
            <v-autocomplete
              v-model="quotation.surfaceTypeId"
              :items="surfaceTypes"
              item-text="surface_type"
              item-value="id"
              label="Surface type that materials to be applied to"
            ></v-autocomplete>
            <v-checkbox
              hint="Leave unticked if not required"
              persistent-hint
              v-model="quotation.sectorSeven"
              label="Sector Seven"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.domVat0"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Riggots supply to be within the CIS scheme?"
            ></v-select>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.domVat2"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Is the customer a contractor in the chain?"
            ></v-select>
          </v-col>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.domVat1"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Is the customer VAT registered?"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col class="col-12 col-md-4 pt-0 pb-2">
            <v-text-field
              label="Purchase Order Number"
              v-model="quotation.purchase_order"
              hint="Please add a customer PO Number"
              persistent-hint></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-4 pt-0 pb-2">
            <div v-if="!quotation.purchase_order_uri">
              <v-btn @click="uploadPo = true" small>
                Upload Purchase Order
              </v-btn>
              <v-dialog max-width="700" v-model="uploadPo">
                <v-card>
                  <app-upload-po
                    :quotationId="quotation.id"
                    @closeUploader="completeUpload"></app-upload-po>
                  <v-card-actions>
                    <v-btn text @click="uploadPo = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div v-else>
              <a
                :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${quotation.purchase_order_uri}`"
                target="_blank">
                <v-btn small>View Purchase Order</v-btn>
              </a>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4 pt-0 pb-2">
            <label>Special Instructions</label>
            <vue-editor
              id="specialInstructions"
              :editor-toolbar="customToolbar"
              v-model="quotation.specialInstructions" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10 col-sm-6 col-md-4 mt-2 mb-10">
            <v-btn x-large @click="saveQuotation">
              Save Changes
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Directions from '@/components/googlemaps/Directions.vue';
import UploadPo from '@/components/Quotations/UploadPO.vue';
import { VueEditor } from "vue2-editor";
import axios from '../../axios';
import { format, parseISO } from 'date-fns';

export default {
  name: 'UpdateQuotation',
  components: {
    VueEditor,
    appDirections: Directions,
    appUploadPo: UploadPo,
  },
  data() {
    return {
      searchTerm: '',
      uploadPo: false,
      customer: {},
      foundCustomers: [],
      quotation: {
        customer: 0,
        date: null,
        drawing_ref: '',  
        site: '',
        details: '',
        sectorSeven: 0,
        department_id: 0,
        distance: '',
        drive_time: '',
        postCode: '',
      },
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      prettyDate: format(parseISO((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), 'EEEE, MMMM do yyyy'),
      departments: [
        { value: 1, text: 'Paint' },
        { value: 2, text: 'Thermo' },
        { value: 3, text: 'Both' },
        { value: 4, text: 'Sale of Goods' },
      ],
      validities: [
        '10 days',
        '30 days',
        '60 days',
        '90 days',
        '180 days',
      ],
      confidences: [
        { value: 1, text: 'Low' },
        { value: 2, text: 'Medium' },
        { value: 3, text: 'High' },
        { value: 4, text: 'Certainty' },
      ],
      contacts: [],
      surfaceTypes: [],
      siteTypes: [],
      workTypes: [],
      customToolbar: [
        ["bold", "italic", "underline", ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    date() {
      this.prettyDate = format(parseISO(this.date), 'EEEE, MMMM do yyyy');
      this.quotation.date = this.date;
    },
  },
  methods: {
    completeUpload(uri) {
      this.quotation.purchase_order_uri = uri;
    },
    populateDistances(value) {
      this.quotation.distance = value.distance;
      this.quotation.drive_time = value.duration;
    },
    getSurfaceTypes() {
      axios.get(`/surfaceTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.surfaceTypes = response.data;
        });
    },
    getSiteTypes() {
      axios.get(`/siteTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.siteTypes = response.data;
        });
    },
    getWorkTypes() {
      axios.get(`/workTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.workTypes = response.data;
        });
    },
    saveQuotation() {
      const postData = {};
      postData.quotation = this.quotation;
      postData.date = this.date;
      axios.post(`/quotations/update.json?token=${this.token}&user=${this.userid}`, postData)
        .then(() => {
          this.$router.push(`/quotations/view/${this.quotation.id}`);
        }); 
    },
    lookupCustomers() {
      if (this.searchTerm.length > 2) {
        const postData = { searchTerm: this.searchTerm };
        axios.post(`/customers/lookup.json?token=${this.token}`, postData)
        .then((response) => {
          this.foundCustomers = response.data.customers;
          this.customer = this.foundCustomers[0];
        });
      }
    },
    checkCustomer() {
      if (this.$route.params.customer) {
        const customerId = this.$route.params.customer;
        axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.quotation.customer = response.data;
        });
      }
    },
    populateCustomer() {
      axios.get(`/customers/get/${this.customer.id}.json?token=${this.token}`)
        .then((response) => {
          this.foundCustomers = [];
          this.customer = '';
          this.quotation.customer = response.data.customer;
          this.quotation.customerId = response.data.customer.id;
          this.quotation.customerAddressOne = response.data.customer.address_1;
          this.quotation.customerAddressTwo = response.data.customer.address_2;
          this.quotation.customerAddressThree = response.data.customer.address_3;
          this.quotation.customerAddressFour = response.data.customer.address_4;
          this.quotation.customerAddressFive = response.data.customer.address_5;
          this.quotation.customerPostcode = response.data.customer.postcode;
          this.quotation.customerTel = response.data.customer.tel;
          this.contacts = response.data.contacts;
          this.getJobRef();
        });
    },
    getJobRef() {
      axios.get(`/quotations/getJobRef.json?token=${this.token}`)
      .then((response) => {
        this.quotation.jobRef = response.data;
      });
    },
    resetDate() {
      this.prettyDate = null;
      this.date = null;
      this.quotation.date = null;
    },
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
        this.foundCustomers.push(this.quotation.customer);
        this.getContacts(this.quotation.customerId);
      });
    },
    getContacts(customerId) {
      axios.get(`/contacts/getByCustomerId/${customerId}.json?token=${this.token}`)
      .then((response) => {
        this.contacts = response.data;
      });
    },
  },
  mounted() {
    this.getQuotation();
    this.getSurfaceTypes();
    this.getSiteTypes();
    this.getWorkTypes();
    this.checkCustomer();
  }
}
</script>
